<template>
  <div>
    <div class="startTrain">
      <div class="leftVideo">
        <div
          class="leftShowBox"
          v-if="kmInfoCoursewareList.length > 0 || kmInfoVideoList.length > 0"
        >
          <video :src="videoUrl" controls v-if="leftShow == true"></video>

          <div v-if="leftShow == false" style="position: relative">
            <i
              class="icon-quanping fullIframe"
              :style="{ color: themeColor }"
              v-if="!isFullscreen"
              @click="toggleFullscreen"
            ></i>
            <i
              class="icon-tuichuquanping noFullIframe"
              :style="{ color: themeColor }"
              v-else
              @click="shrinkFullscreen"
            ></i>
            <div
              id="iframe-container"
              v-bind:style="{ height: isFullscreen ? '100vh' : '480px' }"
            >
              <iframe
                id="my-iframe"
                ref="fileIframe"
                style="width: 100%; height: 100%"
                :src="iframeUrl"
                frameborder="0"
              ></iframe>
            </div>
            <!--              <button @click="toggleFullscreen">全屏</button>-->
            <!--              <button @click="shrinkFullscreen">缩小</button>-->
          </div>
        </div>
      </div>
      <div class="rightCode" v-if="eduPaperList.length > 0">
        <div class="codeTitle">开始答题</div>
        <img
          style="margin-bottom: 10px"
          src="@/assets/images/sign-down.png"
          alt=""
        />
        <div class="signImg">
          <img id="signImage" :src="twoDimensionalCodePicture" />
        </div>
        <div class="“ifSignShow”">
          <el-button v-if="this.isShow" type="primary" @click="showPic"
            >隐藏二维码</el-button
          >
          <el-button v-if="!this.isShow" type="primary" @click="noShowPic"
            >显示二维码</el-button
          >
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="视频" name="video" v-if="kmInfoVideoList.length > 0">
        <div class="videoTabBox">
          <el-button
            type="primary"
            class="moveButton"
            icon="el-icon-arrow-left"
            circle
            @click="leftArrow"
          ></el-button>

          <div class="videoBigBox">
            <div class="videoSwiper" ref="videoSwiper">
              <div
                class="videoLittleBox"
                :style="{
                  border: videoActive == index ? '1px solid' + themeColor : '',
                }"
                v-for="(item, index) in kmInfoVideoList"
                :key="index"
                @click="chooseVideo(item, index)"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.fileName"
                  placement="right"
                >
                  <video class="littleVideo" :src="item.fileCover"></video>
                </el-tooltip>
                <div class="videoTitle">{{ item.fileName }}</div>
              </div>
            </div>
          </div>
          <el-button
            type="primary"
            class="moveButton"
            icon="el-icon-arrow-right"
            circle
            @click="rightArrow"
          ></el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="课件"
        name="file"
        v-if="kmInfoCoursewareList.length > 0"
      >
        <div class="fileBox">
          <el-tag
            v-for="(item, index) in kmInfoCoursewareList"
            :type="fileActive == index ? '' : 'info'"
            size="medium"
            :key="index"
            @click="chooseFile(item, index)"
            >{{ item.fileName }}
          </el-tag>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!--    <el-button @click="printQuestion">打印试题</el-button>-->
  </div>
</template>
<script>
import {
  trainGroupScenDetail,
  twoDimensionalCode,
} from "@/api/onTheJobTraining/training";
import { getCorrespondingData } from "@/api/training/trainGroupScene";
import website from "@/config/website";
import { Base64 } from "js-base64";
import { mapGetters } from "vuex";
import { questionDetails } from "@/api/training/edupaper";

export default {
  data() {
    return {
      id: "",
      taskId: "",
      videoUrl: "",
      kmInfoCoursewareList: [],
      kmInfoVideoList: [],
      eduPaperList: [],
      activeName: "video",
      iframeUrl: "",
      leftShow: true,
      videoActive: 0,
      fileActive: 0,
      activeIndex: 0,
      twoDimensionalCodePicture: "",
      isFullscreen: false,
      isShow: false, //按钮
    };
  },
  computed: {
    ...mapGetters(["themeColor"]),
  },
  components: {},
  created() {},

  methods: {
    toggleFullscreen() {
      const iframe = document.getElementById("my-iframe");
      const iframeContainer = document.getElementById("iframe-container");

      if (!this.isFullscreen) {
        iframeContainer.style.position = "fixed";
        iframeContainer.style.top = "0";
        iframeContainer.style.left = "0";
        iframeContainer.style.width = "100%";
        iframeContainer.style.height = "100vh";
        iframeContainer.style.zIndex = "1001";
        this.isFullscreen = true;
      } else {
        iframeContainer.style.position = "";
        iframeContainer.style.width = "80%"; // 回到原始宽度
        iframeContainer.style.height = "500px"; // 回到原始高度
        this.isFullscreen = false;
      }
    },
    shrinkFullscreen() {
      const iframeContainer = document.getElementById("iframe-container");
      iframeContainer.style.position = "";
      iframeContainer.style.width = "80%"; // 回到原始宽度
      iframeContainer.style.height = "500px"; // 回到原始高度
      this.isFullscreen = false;
    },
    printer() {
      var newWin = window.open("printer", "", "");
      var xiaozhi = document.getElementById("xz").innerText; // 这里的id是要打印的标签里的id
      // innerHtml
      newWin.document.write(xiaozhi);
      newWin.document.location.reload();
      newWin.print();
      newWin.close();
    },
    /**
     * 获取需要打印的试题
     */
    printQuestion() {
      console.log("this.eduPaperList", this.eduPaperList);
      //获取试卷和题目的信息
      //获取所有的试题id
      var map = this.eduPaperList.map((e) => e.id);

      questionDetails(map.join(",")).then((e) => {});
    },
    trainGroupScenDetailList() {
      if (this.id == "undefined") {
        return this.$message({
          type: "warning",
          message: "请先进行签到",
        });
      }
      getCorrespondingData(this.id).then((res) => {
        if (res.data.code == 200) {
          var map = res.data.data.eduPaperList.map((cc) => cc.id);
          var person = {
            testId: map.join(","),
            id: this.id,
            type: "startTraining",
            taskId: this.taskId,
          };
          twoDimensionalCode(person).then((res) => {
            this.twoDimensionalCodePicture =
              "data:image/png;base64," + res.data.data;
          });
          //课件内容
          this.kmInfoCoursewareList = res.data.data.kmInfoCoursewareList;
          //视频内容
          this.kmInfoVideoList = res.data.data.kmInfoVideoList;
          //试题内容
          this.eduPaperList = res.data.data.eduPaperList;

          if (this.kmInfoVideoList.length > 0) {
            this.activeName = "video";
          } else if (this.kmInfoCoursewareList.length > 0) {
            this.activeName = "file";
          }

          if (this.kmInfoVideoList.length != 0) {
            this.leftShow = true;
            this.videoUrl = this.kmInfoVideoList[0].fileCover;
          } else if (this.kmInfoCoursewareList.length != 0) {
            this.leftShow = false;
            this.iframeUrl = this.iframeUrl =
              process.env.VUE_APP_BASE_KKFILEURL +
              "?url=" +
              encodeURIComponent(
                Base64.encode(this.kmInfoCoursewareList[0].fileCover)
              );
            if (process.env.NODE_ENV === "production") {
              const iframe = this.$refs.fileIframe;
              const iframeDocument =
                iframe.contentDocument || iframe.contentWindow.document;
              // 在 iframe 内部隐藏元素
              if (iframeDocument) {
                const elementToHide =
                  iframeDocument.getElementById("button-area");
                if (elementToHide) {
                  elementToHide.style.display = "none";
                }
              }
            }
          }
        }
      });
    },
    // 选择课件
    chooseFile(item, index) {
      this.leftShow = false;
      this.fileActive = index;
      this.iframeUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.fileCover));
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    chooseVideo(item, index) {
      this.videoActive = index;
      this.videoUrl = item.fileCover;
    },
    handleClick() {
      if (this.activeName == "file") {
        this.leftShow = false;
        this.iframeUrl = this.iframeUrl =
          process.env.VUE_APP_BASE_KKFILEURL +
          "?url=" +
          encodeURIComponent(
            Base64.encode(this.kmInfoCoursewareList[0].fileCover)
          );
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;
          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      } else {
        this.leftShow = true;
        this.videoUrl = this.kmInfoVideoList[this.videoActive].fileCover;
      }
    },
    leftArrow() {
      if (this.activeIndex == 0) {
        this.$message({
          type: "warning",
          message: "已经到最左侧了",
        });
        return;
      }
      this.activeIndex--;
      this.$refs.videoSwiper.style.transform =
        "translateX(-" + this.activeIndex * 25 + "%)";
    },
    rightArrow() {
      if (
        this.activeIndex == this.kmInfoVideoList.length - 4 ||
        this.kmInfoVideoList.length <= 4
      ) {
        this.$message({
          type: "warning",
          message: "已经到最右侧了",
        });
        return;
      }
      this.activeIndex++;
      this.$refs.videoSwiper.style.transform =
        "translateX(-" + this.activeIndex * 25 + "%)";
    },
    showPic() {
      this.isShow = false;
      var img = document.getElementById("signImage");
      img.style.filter = "blur(5px)";
    },
    noShowPic() {
      this.isShow = true;
      var img = document.getElementById("signImage");
      img.style.filter = "blur()";
    },
  },
};
</script>
<style lang="scss" scoped>
.startTrain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
}

.leftVideo {
  width: 60%;
  height: 50vh;
}

.leftShowBox {
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
  background-color: #ffffff;
}

.leftShowBox video {
  width: 100%;
  height: 100%;
}

.fileBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.fileBox .el-tag {
  margin: 6px;
  font-size: 15px !important;
  cursor: pointer;
}

::v-deep .el-tabs__content {
  background-color: #ffffff;
}

.videoTabBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.videoBigBox {
  width: 100%;
  height: 100px;
  overflow: hidden;
  margin: 6px;
}

.moveButton {
  height: 32px;
  margin: 40px 0;
}

.videoSwiper {
  width: 100%;
  height: 100%;
  display: flex;
}

.videoLittleBox {
  width: calc(25% - 24px);
  margin: 0 12px;
  height: calc(100% - 6px);
  cursor: pointer;
  border: 1px solid transparent;
  flex-shrink: 0;
}

.littleVideo {
  width: 100%;
  height: calc(100% - 27px);
}

.videoTitle {
  width: 100%;
  text-align: center;
  color: #333333;
}

.rightCode {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .signImg {
    width: 260px;
    height: 260px;
    margin: auto;
    background-image: url("~@/assets/images/signbg2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 5px;

    img {
      width: 100%;
      height: 100%;
      filter: blur(5px);
    }
  }
}
.ifSignShow {
  padding: 20px;
}

.codeTitle {
  width: 100%;
  text-align: center;
  padding: 12px 0;
  font-size: 26px;
  font-weight: bold;
  color: #333333;
}

::v-deep .el-tabs {
  margin-top: 10px;
  width: 60%;
}
#iframe-container {
  width: 80%;
  height: 500px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.fullIframe {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px !important;
  cursor: pointer;
  //z-index: 1001;
}

.noFullIframe {
  position: fixed;
  top: 10px;
  right: 30px;
  font-size: 24px !important;
  z-index: 1002;
  cursor: pointer;
}

#my-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

#controls {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
}
// 答题二维码模糊处理
.blurred {
  filter: blur(5px);
}
</style>
