var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "codeImg" }, [
      _c("img", { attrs: { src: _vm.twoDimensionalCodePicture } }),
    ]),
    _c("div", { staticClass: "signPeople" }, [
      _vm._v("\n    " + _vm._s(this.name) + "\n  "),
    ]),
    _c("div", { staticClass: "signNumber" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/trainSign.png"), alt: "" },
      }),
      _c("div", { staticClass: "number-item" }, [
        _c("div", { staticClass: "number-text" }, [_vm._v(_vm._s(_vm.number))]),
        _c("div", [_vm._v("已签到人数")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }