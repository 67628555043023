var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "startTrain" }, [
        _c("div", { staticClass: "leftVideo" }, [
          _vm.kmInfoCoursewareList.length > 0 || _vm.kmInfoVideoList.length > 0
            ? _c("div", { staticClass: "leftShowBox" }, [
                _vm.leftShow == true
                  ? _c("video", { attrs: { src: _vm.videoUrl, controls: "" } })
                  : _vm._e(),
                _vm.leftShow == false
                  ? _c("div", { staticStyle: { position: "relative" } }, [
                      !_vm.isFullscreen
                        ? _c("i", {
                            staticClass: "icon-quanping fullIframe",
                            style: { color: _vm.themeColor },
                            on: { click: _vm.toggleFullscreen },
                          })
                        : _c("i", {
                            staticClass: "icon-tuichuquanping noFullIframe",
                            style: { color: _vm.themeColor },
                            on: { click: _vm.shrinkFullscreen },
                          }),
                      _c(
                        "div",
                        {
                          style: {
                            height: _vm.isFullscreen ? "100vh" : "480px",
                          },
                          attrs: { id: "iframe-container" },
                        },
                        [
                          _c("iframe", {
                            ref: "fileIframe",
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              id: "my-iframe",
                              src: _vm.iframeUrl,
                              frameborder: "0",
                            },
                          }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
        _vm.eduPaperList.length > 0
          ? _c("div", { staticClass: "rightCode" }, [
              _c("div", { staticClass: "codeTitle" }, [_vm._v("开始答题")]),
              _c("img", {
                staticStyle: { "margin-bottom": "10px" },
                attrs: {
                  src: require("@/assets/images/sign-down.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "signImg" }, [
                _c("img", {
                  attrs: {
                    id: "signImage",
                    src: _vm.twoDimensionalCodePicture,
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "“ifSignShow”" },
                [
                  this.isShow
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.showPic },
                        },
                        [_vm._v("隐藏二维码")]
                      )
                    : _vm._e(),
                  !this.isShow
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.noShowPic },
                        },
                        [_vm._v("显示二维码")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm.kmInfoVideoList.length > 0
            ? _c("el-tab-pane", { attrs: { label: "视频", name: "video" } }, [
                _c(
                  "div",
                  { staticClass: "videoTabBox" },
                  [
                    _c("el-button", {
                      staticClass: "moveButton",
                      attrs: {
                        type: "primary",
                        icon: "el-icon-arrow-left",
                        circle: "",
                      },
                      on: { click: _vm.leftArrow },
                    }),
                    _c("div", { staticClass: "videoBigBox" }, [
                      _c(
                        "div",
                        { ref: "videoSwiper", staticClass: "videoSwiper" },
                        _vm._l(_vm.kmInfoVideoList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "videoLittleBox",
                              style: {
                                border:
                                  _vm.videoActive == index
                                    ? "1px solid" + _vm.themeColor
                                    : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseVideo(item, index)
                                },
                              },
                            },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: item.fileName,
                                    placement: "right",
                                  },
                                },
                                [
                                  _c("video", {
                                    staticClass: "littleVideo",
                                    attrs: { src: item.fileCover },
                                  }),
                                ]
                              ),
                              _c("div", { staticClass: "videoTitle" }, [
                                _vm._v(_vm._s(item.fileName)),
                              ]),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("el-button", {
                      staticClass: "moveButton",
                      attrs: {
                        type: "primary",
                        icon: "el-icon-arrow-right",
                        circle: "",
                      },
                      on: { click: _vm.rightArrow },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.kmInfoCoursewareList.length > 0
            ? _c("el-tab-pane", { attrs: { label: "课件", name: "file" } }, [
                _c(
                  "div",
                  { staticClass: "fileBox" },
                  _vm._l(_vm.kmInfoCoursewareList, function (item, index) {
                    return _c(
                      "el-tag",
                      {
                        key: index,
                        attrs: {
                          type: _vm.fileActive == index ? "" : "info",
                          size: "medium",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.chooseFile(item, index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.fileName) + "\n        ")]
                    )
                  }),
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }