var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "topStep" },
      [
        _c(
          "el-steps",
          {
            attrs: {
              active: _vm.active,
              "finish-status": "success",
              "align-center": "",
            },
          },
          [
            _c("el-step", { attrs: { title: "扫码签到" } }),
            _c("el-step", { attrs: { title: "开始培训" } }),
            _c("el-step", { attrs: { title: "结束培训" } }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("sign", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 0,
              expression: "active == 0",
            },
          ],
          ref: "sign",
          attrs: { row: _vm.rowSign },
        }),
        _c("startTrain", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 1,
              expression: "active == 1",
            },
          ],
          ref: "startTrain",
        }),
        _c("finishTrain", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 2,
              expression: "active == 2",
            },
          ],
          ref: "finishTrain",
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottomBtn" },
      [
        this.active != 0
          ? _c(
              "el-button",
              {
                staticStyle: { width: "120px", height: "42px" },
                attrs: { type: "primary" },
                on: { click: _vm.lastStep },
              },
              [_vm._v("上一步")]
            )
          : _vm._e(),
        this.active != 2
          ? _c(
              "el-button",
              {
                staticStyle: { width: "120px", height: "42px" },
                attrs: { type: "primary" },
                on: { click: _vm.next },
              },
              [_vm._v("下一步")]
            )
          : _vm._e(),
        this.active == 2
          ? _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.personnelExamination },
              },
              [_vm._v("未通过人员重新考试")]
            )
          : _vm._e(),
        this.active == 2
          ? _c("el-button", { on: { click: _vm.EndTraining } }, [
              _vm._v("结束培训"),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }