var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "head-content" }, [
        _c("div", { staticClass: "head-item", staticStyle: { width: "38%" } }, [
          _c("div", { staticClass: "head-text" }, [_vm._v("考试合格率")]),
          _c("div", { staticClass: "item-content" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/pass.png"), alt: "" },
            }),
            _c("div", { staticClass: "item-right" }, [
              _c("span", { staticClass: "right-text" }, [
                _vm._v(_vm._s(_vm.number)),
              ]),
              _vm._v("%\n          "),
              _c("div", [_vm._v("考试合格率")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "head-item", staticStyle: { width: "60%" } }, [
          _c("div", { staticClass: "head-text" }, [
            _vm._v(
              "\n        未完成考试及未通过考试人员名单（" +
                _vm._s(_vm.count) +
                " / " +
                _vm._s(_vm.totalCount) +
                "人）\n      "
            ),
          ]),
          _c("div", { staticClass: "item-content" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/below.png"), alt: "" },
            }),
            _c(
              "div",
              { staticClass: "item-person" },
              _vm._l(_vm.names, function (item, index) {
                return _c("div", { key: index, staticClass: "person" }, [
                  _vm._v(_vm._s(item)),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "pictureBox" },
        [
          _c("div", { staticClass: "head-text" }, [_vm._v("补充培训记录")]),
          _c("avue-form", {
            attrs: {
              option: _vm.option,
              "upload-preview": _vm.rowView,
              "upload-after": _vm.uploadAfter,
              "upload-delete": _vm.uploadDelete,
            },
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          }),
        ],
        1
      ),
      _vm.showFileDialog
        ? _c(
            "CommonDialog",
            {
              attrs: {
                showConfirmBtn: false,
                width: "70%",
                dialogTitle: "附件预览",
              },
              on: {
                cancel: function ($event) {
                  _vm.showFileDialog = false
                },
              },
            },
            [
              _c("iframe", {
                ref: "fileIframe",
                staticStyle: { width: "100%", height: "500px" },
                attrs: { src: _vm.fileUrl, frameborder: "0" },
              }),
            ]
          )
        : _vm._e(),
      _c("video", {
        ref: "video",
        staticStyle: { display: "none" },
        attrs: { id: "myVideo", src: _vm.videoUrl, crossOrigin: "anonymous" },
      }),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: { id: "myCanvas", crossOrigin: "anonymous" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }